export const messages = {
	TOO_SHORT: 'Password must be at least 8 characters long',
	NO_NUMBERS: 'Password must contain numbers',
	NO_SPECIAL_CHAR: 'Password must contain special characters',
	NO_UPPER_LETTERS: 'Password must contain uppercase letters',
	NO_LOWER_LETTERS: 'Password must contain lowercase letters',
};

/**
 * Returns an error message (string) or null, if no error is found.
 * @param password
 */
const checkPasswordForErrors = (password: string): string | null => {
	if (password.length < 8) {
		return messages.TOO_SHORT;
	} else if (!/\d/.test(password)) {
		return messages.NO_NUMBERS;
	} else if (!/\W/.test(password)) {
		return messages.NO_SPECIAL_CHAR;
	} else if (!/[A-Z]/.test(password)) {
		return messages.NO_UPPER_LETTERS;
	} else if (!/[a-z]/.test(password)) {
		return messages.NO_LOWER_LETTERS;
	}

	return null;
};

export default checkPasswordForErrors;

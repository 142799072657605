import IStore from './IStore';

const initialState: IStore = {
	user: {
		info: undefined,
	},
	customers: {
		list: [],
	},
	users: {
		list: [],
		groups: [],
	},
	loading: false,
};

export default initialState;

import { IState as CreateUserState } from '../user/CreateUserDialog';
import { IState as CreateCustomerState } from '../customer/CreateCustomerDialog';

export const isEmailValid = (email: string) => /(\w+|\w+\W*\w+)@(\w+|\w+\W*\w+)((\.\w+)+)$/.test(email);

export const checkForErrors = (state: CreateCustomerState | CreateUserState) => {
	const result: any = {};
	for (const [key, obj] of Object.entries(state)) {
		if (obj.required && obj.value === '') {
			result[key] = { ...obj, error: `Field cannot be empty!` };
		}
		if (obj.value.length > 0 && obj.type === 'email' && !isEmailValid(obj.value)) {
			result[key] = { ...obj, error: 'Please enter a valid e-mail!' };
		}
		if (obj.maxLength && obj.value && obj.value.length > obj.maxLength) {
			result[key] = { ...obj, error: `Maximum ${obj.maxLength} characters allowed in this field.` };
		}
	}
	return result;
};

export const sortIgnoringCase = (a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase());

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
	pilabsRoundedBtn: {
		background: 'var(--pi-labs-blue)',
		zIndex: 100,
		color: '#fff',
		fontSize: '.75rem',
		fontWeight: 'bold',
		borderRadius: '2.5rem',
		padding: '.5rem 1.5rem .5em 1.5em',
		boxShadow:
			'0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
		'&:hover': {
			background: 'var(--pi-labs-dark-blue)',
		},
	},
	tableToolbar: {
		display: 'flex',
		height: '5.3rem',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		paddingBottom: '.5rem',
	},
	buttons: {
		fontWeight: 600,
	},
});

export default useStyles;

import { Reducer } from 'redux';
import keys from '../actions/ActionTypeKeys';
import ActionTypes from '../actions/ActionTypes';
import initialState from '../store/initialState';
import AuthState from '../model/AuthState';

const userInfo: Reducer<AuthState, ActionTypes> = (state = initialState.user, action): AuthState => {
	switch (action.type) {
		case keys.SIGN_IN_WITH_CODE_RESOLVED:
			return { ...state, info: action.payload };
		case keys.SIGN_IN_RESOLVED:
			return { ...state, info: action.payload };
		case keys.SIGN_OUT_RESOLVED:
		case keys.SIGN_IN_REJECTED:
			return { ...state, info: null };
		default:
			return state;
	}
};

export default userInfo;

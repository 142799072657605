import React, { useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Button,
	DialogActions,
	DialogProps,
	DialogContentText,
	makeStyles,
} from '@material-ui/core';
import { deleteCustomer, fetchCustomers } from '../../actions/CustomerActions';
import { useDispatch, useSelector } from 'react-redux';
import IStore from '../../store/IStore';
import Spinner from '../accessories/Spinner';
import { Customer } from '../../model/CustomerState';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
	buttons: {
		fontWeight: 600,
	},
	disabledButtons: {
		fontWeight: 600,
		color: '#afafaf',
	},
});

type CustomerDialogProps = {
	handleClose: () => void;
	customer?: Customer;
} & DialogProps;

const DeleteCustomerDialog = ({ handleClose, open, customer }: CustomerDialogProps) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const loading = useSelector<IStore, boolean>((state) => state.loading);
	const [isDeleteEnable, setIsDeleteEnable] = useState<boolean>(false);

	const customerDelete = (id: string) => {
		return dispatch<any>(deleteCustomer(id))
			.then(async (data: any) => {
				toast.success(`User ${customer?.name} has been deleted`, {
					autoClose: 3000,
					position: toast.POSITION.BOTTOM_LEFT,
				});
				await dispatch(fetchCustomers());
				return;
			})
			.catch((err: Error) => {
				toast.error(err.message, {
					autoClose: 3000,
					position: toast.POSITION.BOTTOM_LEFT,
				});
			});
	};

	const onClose = async ({ currentTarget: target }: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if (target.name === 'delete' && customer && isDeleteEnable) {
			await customerDelete(customer.id as string);
		}
		setIsDeleteEnable(false);
		handleClose();
	};

	const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
		setIsDeleteEnable(target.value.toUpperCase() === 'DELETE' ? true : false);
	};

	return (
		<Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Delete Customer</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Do you really want to delete the customer{' '}
					<span style={{ fontWeight: 'bold' }}>{customer?.name}</span>? Please type{' '}
					<span style={{ color: 'red' }}>Delete</span> in the field below to confirm this action.
				</DialogContentText>
				<TextField
					name="delete"
					margin="dense"
					label="Type the word Delete here"
					placeholder="Type the word Delete here"
					type="text"
					onChange={handleChange}
					fullWidth
				/>
			</DialogContent>
			<DialogActions>
				{loading && <Spinner size={0.9} borderWidth=".15rem" />}
				<Button
					name="delete"
					className={!isDeleteEnable ? classes.disabledButtons : classes.buttons}
					onClick={
						isDeleteEnable
							? onClose
							: () => {
									//do nothing
							  }
					}
					color="primary"
					size="small"
					disabled={loading && !isDeleteEnable}
				>
					Delete
				</Button>
				<Button name="cancel" className={classes.buttons} onClick={onClose} color="secondary" size="small">
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteCustomerDialog;

import React, { useEffect, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Button,
	Select,
	MenuItem,
	DialogActions,
	DialogProps,
	makeStyles,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import IStore from '../../store/IStore';
import Spinner from '../accessories/Spinner';
import { createUser, fetchUsers, updateUser } from '../../actions/UserActions';
import { UserRole } from '../../model/UserState';
import User from '../../model/User';
import { checkForErrors } from '../shared/functions';
import { toast } from 'react-toastify';
import toastOptions from '../accessories/toast-options';

const useStyles = makeStyles({
	buttons: {
		fontWeight: 600,
	},
});

const REQUIRED = '* Required';

type StatePiece = {
	value: string;
	type: string;
	required: boolean;
	error?: string | null;
};

export interface IState {
	name: StatePiece;
	email: StatePiece;
	group: StatePiece;
}

type CreateUserDialogProps = {
	editedUser?: User | null;
	handleClose: () => void;
} & DialogProps;

const initialState = {
	name: { value: '', type: 'text', required: true },
	email: { value: '', type: 'email', required: false },
	group: { value: '', type: 'select', required: true },
};

export function getGroupLowestPrecedence(user: User | undefined | null, userGroups: UserRole[]) {
	return user
		? Math.min(...userGroups.filter((ug) => user.groups.includes(ug.GroupName)).map((g: any) => g.Precedence))
		: 0;
}

const CreateUserDialog = ({ handleClose, open, editedUser }: CreateUserDialogProps) => {
	const classes = useStyles();
	const [state, setState] = useState<IState>(initialState);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const dispatch = useDispatch();
	const loading = useSelector<IStore, boolean>((state) => state.loading);
	const user = useSelector<IStore, User | undefined | null>((state) => state.user.info);
	const userGroups = useSelector<IStore, UserRole[]>((state) => state.users.groups);

	useEffect(() => {
		if (editedUser) {
			const { name, email, groups } = editedUser;
			setState((prev) => ({
				...prev,
				name: { ...prev.name, value: name || '' },
				email: { ...prev.email, value: email },
				group: { ...prev.group, value: groups[0] },
			}));
			setErrorMessage(null);
		}
	}, [editedUser]);

	const onClose = ({ currentTarget: target }: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		const result = checkForErrors(state);
		if (Object.keys(result).length > 0 && target.name === 'create') {
			setState((previous) => ({ ...previous, ...result }));
			setErrorMessage(null);
			return;
		}

		if (target.name !== 'create') {
			setState(initialState);
			setErrorMessage(null);
			handleClose();
			return;
		}

		const handleOperation = editedUser ? updateUser : createUser;

		dispatch<any>(
			handleOperation({
				username: editedUser ? editedUser.username : null,
				name: state.name.value,
				email: state.email.value,
				groups: [state.group.value],
			}),
		)
			.then(() => {
				setState(initialState);
				setErrorMessage(null);
				dispatch(fetchUsers());
				toast.success(`User ${state.name.value} successfully create!`, toastOptions);
				handleClose();
			})
			.catch((error: Error) => {
				if (error.message.toLocaleLowerCase().includes('already exists')) {
					setState((previousState: IState) => ({
						...previousState,
						email: {
							...previousState.email,
							error: `A user with E-mail ${state.email.value} already exists`,
						},
					}));
					setErrorMessage(null);
				} else {
					setErrorMessage(`Error: '${error.message}'`);
				}
			});
	};

	const handleChange = ({ target }: React.ChangeEvent<any>) => {
		setState((previous: any) => ({
			...previous,
			[target.name]: { ...previous[target.name], value: target.value, error: null },
		}));
		setErrorMessage(null);
	};

	const lowestPrecedence = getGroupLowestPrecedence(user, userGroups);
	const hasEditedUserLowerPrecedence = editedUser
		? lowestPrecedence > getGroupLowestPrecedence(editedUser, userGroups)
		: false;

	return (
		<Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth="xs">
			<DialogTitle id="form-dialog-title">{editedUser ? 'Edit User' : 'Create User'}</DialogTitle>
			<DialogContent>
				{errorMessage && <Typography style={{ color: '#f44336' }}>{errorMessage}</Typography>}
				<TextField
					name="name"
					margin="dense"
					label="Display Name"
					type="text"
					value={state.name.value}
					onChange={handleChange}
					error={Boolean(state.name.error)}
					helperText={state.name.error || REQUIRED}
					fullWidth
				/>
				<TextField
					margin="dense"
					name="email"
					label="Email"
					type="email"
					value={state.email.value}
					onChange={handleChange}
					error={Boolean(state.email.error)}
					helperText={state.email.error || REQUIRED}
					fullWidth
				/>
				<Select
					displayEmpty
					name="group"
					value={state.group.value}
					onChange={handleChange}
					error={Boolean(state.group.error)}
					style={{ color: state.group.error ? '#f50057' : 'inherit' }}
					disabled={user?.username === editedUser?.username || hasEditedUserLowerPrecedence}
					fullWidth
					required
				>
					<MenuItem value="">Choose a role for the user</MenuItem>
					{userGroups.map((ug) =>
						(user?.groups.some((g) => ['Root', 'Admin'].includes(g)) && ug.GroupName === 'Technician') ||
						(ug.GroupName !== 'Technician' && lowestPrecedence && lowestPrecedence <= ug.Precedence!) ||
						(ug.GroupName !== 'Technician' && hasEditedUserLowerPrecedence) ? (
							<MenuItem key={ug.GroupName} value={ug.GroupName}>
								{ug.GroupName}
							</MenuItem>
						) : null,
					)}
				</Select>
			</DialogContent>
			<DialogActions>
				{loading && <Spinner size={0.9} borderWidth=".15rem" />}
				<Button
					name="create"
					className={classes.buttons}
					onClick={onClose}
					color="primary"
					size="small"
					disabled={loading}
				>
					{editedUser ? 'Save' : 'Create'}
				</Button>
				<Button name="cancel" className={classes.buttons} onClick={onClose} color="secondary" size="small">
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CreateUserDialog;

import cognitoConfig from '../../config.json';

export const config = {
	mandatorySignIn: true,
	region: 'eu-west-1',
	userPoolId: cognitoConfig.userPoolId,
	userPoolWebClientId: cognitoConfig.clientId,
};

export default config;

import React, { FC } from 'react';
import './css.css';
import { Link, useLocation } from 'react-router-dom';
import { routes } from '../router/Routes';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import IStore from '../../store/IStore';
import logo from '../../assets/logo.png';
import ProfileMenu from '../auth/ProfileMenu';
import Footer from '@chaptesi/licenses/components/Footer';

const theme = createMuiTheme({
	palette: {
		primary: { main: '#00baff' },
		secondary: { main: '#ff5349' },
	},
});

interface ILayoutProps {
	children?: JSX.Element | Array<JSX.Element>;
}

const Layout: FC<ILayoutProps> = function ({ children }) {
	return (
		<MuiThemeProvider theme={theme}>
			<Header />
			<div className={'ch-content-filler'}>{children}</div>
			<Footer />
		</MuiThemeProvider>
	);
};

interface IHeaderProps {
	children?: React.ReactNode[];
}

const Header: FC<IHeaderProps> = function () {
	const selectedClass = 'ch-header-link ch-grey-text ch-selected';
	const notSelectedClass = 'ch-header-link ch-grey-text';
	const { pathname: path } = useLocation();
	const userGroups = useSelector<IStore, string[]>((state) => state.user.info?.groups || []);

	return (
		<header className={'ch-header'}>
			<nav className={'ch-header-nav'}>
				<Link to={'/'}>
					<img className={'ch-header-logo'} src={logo} alt={"PI Labs' logo"} />
				</Link>
				<div className="ch-header-nav-links">
					{Object.values(routes).map((v) =>
						v.roles.some((r) => userGroups.includes(r)) && !v.hide ? (
							<Link
								key={v.uri}
								className={`/${path.split('/')[1]}` === v.uri ? selectedClass : notSelectedClass}
								to={v.uri}
							>
								{v.label}
							</Link>
						) : null,
					)}
				</div>
				<ProfileMenu />
			</nav>
		</header>
	);
};

export default Layout;

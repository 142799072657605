import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PilabsButton from '../accessories/PilabsButton';
import { signOut } from '../../actions/AuthActions';
import Layout from '../layout/Layout';
import IStore from '../../store/IStore';
import User from '../../model/User';

const Profile = () => {
	const user = useSelector<IStore, User | null | undefined>((state) => state.user.info);
	const dispatch = useDispatch();
	return (
		<Layout>
			<div
				style={{
					display: 'grid',
					height: 'auto',
					justifyContent: 'center',
					alignItems: 'center',
					width: '100%',
				}}
			>
				<h1>Profile</h1>&nbsp;
				<br />
				<span>
					Email: <b>{user?.email}</b>
				</span>
				&nbsp;
				<br />
				<span>
					Customer: <b>{user?.account}</b>
				</span>
				&nbsp;
				<br />
				<span>
					Role: <b>{user?.groups.join(', ')}</b>
				</span>
				&nbsp;
				<br />
				<PilabsButton onClick={() => dispatch(signOut())}>Logout</PilabsButton>
			</div>
		</Layout>
	);
};

export default Profile;

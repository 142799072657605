import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Footer from '@chaptesi/licenses/components/Footer';
import logo from '@chaptesi/licenses/assets/logo.png';
import { LicenceProps } from './LicensesManager';
import { fetchLicensesPublic } from '../../api/licenses';
import { toast } from 'react-toastify';
import toastOptions from '../accessories/toast-options';
import Spinner from '../accessories/Spinner';

const useStyles = makeStyles({
	pilabs: {
		height: '100%',
		width: '100vw',
		display: 'grid',
		gridTemplateColumns: '1fr',
		gridTemplateRows: 'auto min-content',
		gridTemplateAreas: ` 
        "main"
        "footer"`,
		margin: 0,
		gridColumnGap: 0,
		gridRowGap: 0,
		justifyItems: 'stretch',
		textAlign: 'center',
		alignItems: 'start',
		justifyContent: 'center',
		'& > .text-light-gray': {
			color: '#d6d5d5',
		},
	},
	card: {
		overflow: 'auto',
		width: 'calc( 100vw / 1.8 )',
		height: '400px',
	},
	box: {
		width: '350px',
		backgroundColor: 'rgba(255,255,255,0.8)',
		padding: '30px 47px',
		display: 'grid',
		gridTemplateColumns: '1fr',
		gridTemplateRows: 'min-content 1fr auto 2fr',
		gridTemplateAreas: `
        "boxHeader"
        "."
        "boxContent"
        "."`,
	},
	boxContent: {
		gridArea: 'boxContent',
	},
	boxHeader: {
		paddingTop: '1rem',
		width: '100%',
	},
	textAlignLeft: {
		float: 'left',
		marginLeft: 'calc( calc( 100vw - 48px - calc(100vw / 1.8 )) / 2 )',
	},
	textAlignRight: {
		float: 'right',
		marginRight: 'calc( calc( 100vw + 48px - calc(100vw / 1.8 )) / 2 )',
	},
	bottom: {
		padding: '10px 20px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	main: {
		gridArea: 'main',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
});

const Licenses = () => {
	const classes = useStyles();
	const [licenses, setLicenses] = useState<LicenceProps[]>([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		fetchLicensesPublic()
			.then((data) => {
				setLicenses(data);
			})
			.catch((error) => {
				toast.error('Error fetching licenses. ' + error.message, toastOptions);
			})
			.finally(() => setLoading(false));
	}, []);

	return (
		<div className={classes.pilabs}>
			<div className={classes.main} style={{ overflow: 'hidden' }}>
				<div className={classes.box} style={{ width: '100%', height: '100%' }}>
					<div className={classes.boxHeader} style={{ padding: '.5rem 0 .5rem .5rem' }}>
						<div className={classes.textAlignLeft}>
							<img src={logo} alt="PI Labs" className="piLabsLogo" />
						</div>
						<div className={classes.textAlignRight}>Third-Party Software</div>
					</div>
					<div className={classes.boxContent} style={{ overflowX: 'hidden', overflowY: 'auto' }}>
						{licenses.length ? (
							<Grid container direction="column" justify="flex-start" alignItems="center" spacing={3}>
								{licenses.map((license) => (
									<Grid item xs={12}>
										<Card className={`${classes.card} pixida-scrollbar`} elevation={3}>
											<CardContent>
												<Typography component="h5" variant="h5">
													{license.libraryName}
												</Typography>
												<Typography
													variant="subtitle1"
													color="textSecondary"
													style={{ whiteSpace: 'pre-line' }}
												>
													{license.licenseText.replace(/^ +/gm, '')}
												</Typography>
											</CardContent>
										</Card>
									</Grid>
								))}
							</Grid>
						) : (
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								{loading ? <Spinner /> : 'No licenses'}
							</div>
						)}
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Licenses;

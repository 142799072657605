import React, { useEffect, useState } from 'react';
import MaterialTable, { MTableBodyRow, MTableHeader } from 'material-table';
import { Button, Paper, TablePagination } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import Layout from '../layout/Layout';
import { useDispatch, useSelector } from 'react-redux';
import IStore from '../../store/IStore';
import CustomerState, { Customer } from '../../model/CustomerState';
import { fetchCustomers } from '../../actions/CustomerActions';
import CreateCustomerDialog from './CreateCustomerDialog';
import DeleteCustomerDialog from './DeleteCustomerDialog';
import useStyles from '../shared/useStyles';
import { sortIgnoringCase } from '../shared/functions';

const styles = {
	width: '100%',
	padding: '1rem 0',
};

const columns: any = [
	{
		title: 'Customer',
		field: 'name',
		defaultSort: 'asc',
		customSort: ({ name: a }: any, { name: b }: any) => sortIgnoringCase(a, b),
	},
	{ title: 'Fleet Manager', field: 'fleetManager' },
];

const Toolbar = (props: any) => {
	return (
		<div className={props.className}>
			<div className={'ch-grey-text ch-total-customer-variant'}>Total customers: {props.total}</div>
			{props.children}
		</div>
	);
};

const Pagination = (props: any) => (
	<TablePagination
		{...props}
		SelectProps={{
			renderValue: (value: any) => <div>Rows per page: {value}</div>,
		}}
	/>
);

const CustomerManagement = () => {
	const classes = useStyles();
	const { list: customers } = useSelector<IStore, CustomerState>((state) => state.customers);
	const loading = useSelector<IStore, boolean>((state) => state.loading);
	const dispatch = useDispatch();

	const [customerToDelete, setCustomerToDelete] = useState<Customer>();

	useEffect(() => {
		dispatch(fetchCustomers());
	}, [dispatch]);

	const [dialogOpen, setDialogOpen] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

	return (
		<Layout>
			<MaterialTable
				isLoading={loading}
				components={{
					Toolbar: (props) => (
						<Toolbar total={customers.length} className={classes.tableToolbar} {...props}>
							<Button
								className={`${classes.pilabsRoundedBtn} ch-create-btn`}
								onClick={() => setDialogOpen(true)}
								startIcon={<AddIcon className={' ch-add-icon-create-btn'} />}
								size="small"
							>
								Create Customer
							</Button>
						</Toolbar>
					),
					Container: (props) => <Paper elevation={0} {...props} />,
					Header: (props) => <MTableHeader className={'ch-grey-text'} {...props} />,
					Row: (props) => <MTableBodyRow className={'ch-diplsay-none-if-empty'} {...props} />,
					Pagination: (props) => <Pagination {...props} />,
				}}
				actions={[
					(c) => ({
						icon: 'delete',
						tooltip: 'Delete Customer',
						onClick: (event, c) => {
							setCustomerToDelete(c as Customer);
							setDeleteDialogOpen(true);
						},
						disabled: (c as Customer).id?.toUpperCase().replace(' ', '') === 'PILABS',
					}),
				]}
				options={{
					actionsColumnIndex: -1,
					search: false,
					filtering: true,
					maxBodyHeight: 'calc(100vh - 170px - 3.75rem)',
					pageSizeOptions: [5, 10, 15, 20, 25],
					headerStyle: {
						fontWeight: 600,
						fontSize: '.75rem',
						textTransform: 'uppercase',
						textDecoration: 'none',
						color: 'var(--trip-text-gray)',
					},
					emptyRowsWhenPaging: true,
				}}
				style={styles}
				title={'Customers'}
				columns={columns}
				data={customers}
				localization={{
					body: {
						emptyDataSourceMessage: 'No records to display',
						filterRow: {
							filterTooltip: 'filter',
						},
					},
				}}
			/>
			<CreateCustomerDialog handleClose={() => setDialogOpen(false)} open={dialogOpen} />
			<DeleteCustomerDialog
				handleClose={() => setDeleteDialogOpen(false)}
				customer={customerToDelete}
				open={deleteDialogOpen}
			/>
		</Layout>
	);
};

export default CustomerManagement;

import ActionTypes from '../actions/ActionTypes';
import { PENDING, RESOLVED, REJECTED } from '../actions/AsyncStates';

export const handlePromises = (store: any) => (next: any) => (action: ActionTypes) => {
	if (action.payload instanceof Promise) {
		store.dispatch({ type: PENDING(action.type) });
		return action.payload
			.then((value) => {
				store.dispatch({ type: RESOLVED(action.type), payload: value });
				return value;
			})
			.catch((error) => {
				store.dispatch({ type: REJECTED(action.type), payload: error });
				throw error;
			});
	}
	next(action);
};

import axios from './interceptors';
import { AUTH_URL } from './constants';
import jwtDecode from 'jwt-decode';
import User from '../model/User';
import { isValidDomain } from '../utils/url';

export type QueriesProps = {
	[key: string]: string;
};

const getAuthenticatedUserInfo = (token: string | null): User | null => {
	const decodedToken = token ? jwtDecode<any>(token) : null;
	if (decodedToken) {
		return {
			name: decodedToken.name,
			username: decodedToken['cognito:username'],
			account: decodedToken.account,
			email: decodedToken.email,
			groups: decodedToken['cognito:groups'],
		};
	}
	return decodedToken;
};

export const removeTokens = () => {
	// @ts-ignore
	window.localStorage.removeItem('idToken');
	// @ts-ignore
	window.localStorage.removeItem('accessToken');
};

export const getInfoFromUrl = (...info: string[]) => {
	// @ts-ignore
	const search = window.location.search.replace('?', '');
	const queryInfo: QueriesProps = {};
	for (const i of info) {
		if (search.includes(i)) {
			const parts = search.split('&');
			for (const part of parts) {
				if (part.split('=')[0] === i) {
					queryInfo[i] = part.split('=')[1] || part.split('=')[0];
				}
			}
		}
	}
	return queryInfo;
};

export const authenticate = (code: any) => {
	if (code) {
		return axios
			.post(`${AUTH_URL}/token`, { code }, { withCredentials: true })
			.then((response) => {
				// @ts-ignore
				window.localStorage.setItem('idToken', response.data.idToken);
				// @ts-ignore
				window.localStorage.setItem('accessToken', response.data.accessToken);
				return getAuthenticatedUserInfo(response.data.idToken);
			})
			.catch((error) => {
				console.error(error);
				return null;
			});
	}
	return Promise.reject(false);
};

export const requestAuthCode = ({ username, password }: any) => {
	return axios
		.post(`${AUTH_URL}/login`, { username, password })
		.then((response) => {
			if (response.data.errorCode) {
				throw new Error(response.data.errorMessage);
			}
			return response;
		})
		.catch((error) => {
			throw error;
		});
};

export const refreshAuth = () => {
	return axios
		.post(`${AUTH_URL}/refresh`, {}, { withCredentials: true })
		.then((response) => {
			// @ts-ignore
			const uriParams = new URLSearchParams(window.location.search);
			const redirectUri = uriParams.get('redirect_uri') || uriParams.get('redirectUri');
			if (redirectUri && isValidDomain(redirectUri)) {
				window.open(redirectUri, '_self');
			}
			// @ts-ignore
			window.localStorage.setItem('idToken', response.data.idToken);
			// @ts-ignore
			window.localStorage.setItem('accessToken', response.data.accessToken);
			return {
				userInfo: getAuthenticatedUserInfo(response.data.idToken),
				idToken: response.data.idToken,
				accessToken: response.data.accessToken,
			};
		})
		.catch(() => {
			removeTokens();
			return { userInfo: null };
		});
};

export const signOut = () => {
	// @ts-ignore
	const accessToken = window.localStorage.getItem('accessToken');
	// @ts-ignore
	const idToken = window.localStorage.getItem('idToken');
	return axios
		.post(`${AUTH_URL}/logout`, { accessToken, idToken }, { withCredentials: true })
		.then(() => {
			removeTokens();
		})
		.catch((error) => {
			throw error;
		});
};

import keys from './ActionTypeKeys';
import { IFetchAuthCode, IForgotPassword, IResetPassword, ISignIn, ISignInWithCode, ISignOut } from './IActions';
import { authenticate, refreshAuth, requestAuthCode, signOut as callSignOut } from '../api/auth';
import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';

export const signIn = (): ISignIn => ({
	type: keys.SIGN_IN,
	payload: refreshAuth()
		.then(({ userInfo }: any) => userInfo)
		.catch((error: Error) => error),
});

export const signOut = (): ISignOut => ({
	type: keys.SIGN_OUT,
	payload: callSignOut(),
});

export const fetchAuthCode = (username: string, password: string): IFetchAuthCode => ({
	type: keys.FETCH_AUTH_CODE,
	payload: requestAuthCode({ username, password }),
});

export const signInWithCode = (code: string): ISignInWithCode => ({
	type: keys.SIGN_IN_WITH_CODE,
	payload: authenticate(code),
});

export const requestForgotPassword = (username: string, redirectUri: string): IForgotPassword => ({
	type: keys.HANDLE_FORGOT_PASSWORD,
	payload: Auth.forgotPassword(username, { redirectUri }),
});

export const requestResetPassword = (username: string, code: string, password: string): IResetPassword => ({
	type: keys.HANDLE_RESET_PASSWORD,
	payload: Auth.forgotPasswordSubmit(username, code, password),
});

export const requestSetNewPassword = (user: CognitoUser, password: string): IForgotPassword => ({
	type: keys.HANDLE_FORGOT_PASSWORD,
	payload: Auth.completeNewPassword(user, password),
});

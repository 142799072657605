enum ActionTypeKeys {
	SIGN_IN = 'SIGN_IN',
	SIGN_IN_PENDING = 'SIGN_IN_PENDING',
	SIGN_IN_RESOLVED = 'SIGN_IN_RESOLVED',
	SIGN_IN_REJECTED = 'SIGN_IN_REJECTED',

	SIGN_OUT = 'SIGN_OUT',
	SIGN_OUT_PENDING = 'SIGN_OUT_PENDING',
	SIGN_OUT_RESOLVED = 'SIGN_OUT_RESOLVED',
	SIGN_OUT_REJECTED = 'SIGN_OUT_REJECTED',

	SIGN_IN_WITH_CODE = 'SIGN_IN_WITH_CODE',
	SIGN_IN_WITH_CODE_PENDING = 'SIGN_IN_WITH_CODE_PENDING',
	SIGN_IN_WITH_CODE_RESOLVED = 'SIGN_IN_WITH_CODE_RESOLVED',
	SIGN_IN_WITH_CODE_REJECTED = 'SIGN_IN_WITH_CODE_REJECTED',

	FETCH_AUTH_CODE = 'FETCH_AUTH_CODE',
	FETCH_AUTH_CODE_PENDING = 'FETCH_AUTH_CODE_PENDING',
	FETCH_AUTH_CODE_RESOLVED = 'FETCH_AUTH_CODE_RESOLVED',
	FETCH_AUTH_CODE_REJECTED = 'FETCH_AUTH_CODE_REJECTED',

	HANDLE_FORGOT_PASSWORD = 'HANDLE_FORGOT_PASSWORD',
	HANDLE_FORGOT_PASSWORD_PENDING = 'HANDLE_FORGOT_PASSWORD_PENDING',
	HANDLE_FORGOT_PASSWORD_RESOLVED = 'HANDLE_FORGOT_PASSWORD_RESOLVED',
	HANDLE_FORGOT_PASSWORD_REJECTED = 'HANDLE_FORGOT_PASSWORD_REJECTED',

	HANDLE_RESET_PASSWORD = 'HANDLE_RESET_PASSWORD',
	HANDLE_RESET_PASSWORD_PENDING = 'HANDLE_RESET_PASSWORD_PENDING',
	HANDLE_RESET_PASSWORD_RESOLVED = 'HANDLE_RESET_PASSWORD_RESOLVED',
	HANDLE_RESET_PASSWORD_REJECTED = 'HANDLE_RESET_PASSWORD_REJECTED',

	HANDLE_SET_NEW_PASSWORD = 'HANDLE_FORGOT_PASSWORD',
	HANDLE_SET_NEW_PASSWORD_PENDING = 'HANDLE_SET_NEW_PASSWORD_PENDING',
	HANDLE_SET_NEW_PASSWORD_RESOLVED = 'HANDLE_SET_NEW_PASSWORD_RESOLVED',
	HANDLE_SET_NEW_PASSWORD_REJECTED = 'HANDLE_SET_NEW_PASSWORD_REJECTED',

	FETCH_CUSTOMERS = 'FETCH_CUSTOMERS',
	FETCH_CUSTOMERS_PENDING = 'FETCH_CUSTOMERS_PENDING',
	FETCH_CUSTOMERS_RESOLVED = 'FETCH_CUSTOMERS_RESOLVED',
	FETCH_CUSTOMERS_REJECTED = 'FETCH_CUSTOMERS_REJECTED',

	CREATE_CUSTOMER = 'CREATE_CUSTOMER',
	CREATE_CUSTOMER_PENDING = 'CREATE_CUSTOMER_PENDING',
	CREATE_CUSTOMER_RESOLVED = 'CREATE_CUSTOMER_RESOLVED',
	CREATE_CUSTOMER_REJECTED = 'CREATE_CUSTOMER_REJECTED',

	DELETE_CUSTOMER = 'DELETE_CUSTOMER',
	DELETE_CUSTOMER_PENDING = 'DELETE_CUSTOMER_PENDING',
	DELETE_CUSTOMER_RESOLVED = 'DELETE_CUSTOMER_RESOLVED',
	DELETE_CUSTOMER_REJECTED = 'DELETE_CUSTOMER_REJECTED',

	FETCH_USERS = 'FETCH_USERS',
	FETCH_USERS_PENDING = 'FETCH_USERS_PENDING',
	FETCH_USERS_RESOLVED = 'FETCH_USERS_RESOLVED',
	FETCH_USERS_REJECTED = 'FETCH_USERS_REJECTED',

	FETCH_GROUPS = 'FETCH_GROUPS',
	FETCH_GROUPS_PENDING = 'FETCH_GROUPS_PENDING',
	FETCH_GROUPS_RESOLVED = 'FETCH_GROUPS_RESOLVED',
	FETCH_GROUPS_REJECTED = 'FETCH_GROUPS_REJECTED',

	CREATE_USER = 'CREATE_USER',
	CREATE_USER_PENDING = 'CREATE_USER_PENDING',
	CREATE_USER_RESOLVED = 'CREATE_USER_RESOLVED',
	CREATE_USER_REJECTED = 'CREATE_USER_REJECTED',

	UPDATE_USER = 'UPDATE_USER',
	UPDATE_USER_PENDING = 'UPDATE_USER_PENDING',
	UPDATE_USER_RESOLVED = 'UPDATE_USER_RESOLVED',
	UPDATE_USER_REJECTED = 'UPDATE_USER_REJECTED',

	DELETE_USER = 'DELETE_USER',
	DELETE_USER_PENDING = 'DELETE_USER_PENDING',
	DELETE_USER_RESOLVED = 'DELETE_USER_RESOLVED',
	DELETE_USER_REJECTED = 'DELETE_USER_REJECTED',
}

export default ActionTypeKeys;

import { Reducer } from 'redux';
import ActionTypes from '../actions/ActionTypes';
import initialState from '../store/initialState';
import keys from '../actions/ActionTypeKeys';
import UserState from '../model/UserState';

const userInfo: Reducer<UserState, ActionTypes> = (state = initialState.users, action): UserState => {
	switch (action.type) {
		case keys.FETCH_USERS_RESOLVED:
			return { ...state, list: action.payload.data };
		case keys.DELETE_USER_RESOLVED:
			return { ...state, list: state.list };
		case keys.FETCH_GROUPS_RESOLVED:
			return { ...state, groups: action.payload.data };
		default:
			return state;
	}
};

export default userInfo;

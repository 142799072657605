import { ICreateCustomer, IDeleteCustomer, IFetchCustomers } from './IActions';
import keys from './ActionTypeKeys';
import { createNewCustomer, fetchCustomers as fetchCustomerList, deleteCustomerById } from '../api/customer';
import { Customer } from '../model/CustomerState';

export const fetchCustomers = (): IFetchCustomers => ({
	type: keys.FETCH_CUSTOMERS,
	payload: fetchCustomerList(),
});

export const createCustomer = (customer: Customer): ICreateCustomer => ({
	type: keys.CREATE_CUSTOMER,
	payload: createNewCustomer(customer),
});

export const deleteCustomer = (id: string): IDeleteCustomer => ({
	type: keys.DELETE_CUSTOMER,
	payload: deleteCustomerById(id),
});

import React, { useEffect, useState } from 'react';
import MaterialTable, { MTableBodyRow, MTableHeader } from 'material-table';
import { Button, Paper, TablePagination } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import Layout from '../layout/Layout';
import { useDispatch, useSelector } from 'react-redux';
import IStore from '../../store/IStore';
import UserState, { UserRole } from '../../model/UserState';
import User from '../../model/User';
import { fetchUsers } from '../../actions/UserActions';
import CreateUserDialog from './CreateUserDialog';
import DeleteUserDialog from './DeleteUserDialog';
import useStyles from '../shared/useStyles';
import { sortIgnoringCase } from '../shared/functions';
import { getGroupLowestPrecedence } from './CreateUserDialog';

const styles = {
	width: '100%',
	padding: '1rem 0',
};

const columns: any = [
	{
		title: 'User',
		field: 'name',
		defaultSort: 'asc',
		customSort: ({ name: a }: any, { name: b }: any) => sortIgnoringCase(a || '', b || ''),
	},
	{ title: 'Email', field: 'email' },
	{
		title: 'Role',
		field: 'groups',
		render: (rowData: User) => rowData.groups.length > 0 && rowData.groups.reduce((s, g) => `${s} | ${g}`),
	},
];

const Toolbar = (props: any) => {
	return (
		<div className={props.className}>
			<div className={'ch-grey-text ch-total-customer-variant'}>Total users: {props.total}</div>
			{props.children}
		</div>
	);
};

const Pagination = (props: any) => (
	<TablePagination
		{...props}
		SelectProps={{
			renderValue: (value: any) => <div>Rows per page: {value}</div>,
		}}
	/>
);

const getEditPermissionStatus = (signedInUser: User | null | undefined, listedUser: User, userGroups: UserRole[]) => {
	const canEdit =
		getGroupLowestPrecedence(signedInUser, userGroups) <= getGroupLowestPrecedence(listedUser, userGroups);
	return {
		tooltip: canEdit ? 'Edit User' : 'Insufficient Permission',
		disabled: !canEdit,
	};
};

const getDeletePermissionStatus = (signedInUser: User | null | undefined, listedUser: User, userGroups: UserRole[]) => {
	let canDelete =
		getGroupLowestPrecedence(signedInUser, userGroups) <= getGroupLowestPrecedence(listedUser, userGroups);
	if (signedInUser && listedUser && signedInUser.email === listedUser.email) {
		canDelete = false;
	}
	return {
		tooltip: canDelete ? 'Delete User' : 'Insufficient Permission',
		disabled: !canDelete,
	};
};

const UserManagement = () => {
	const classes = useStyles();
	const { list: users } = useSelector<IStore, UserState>((state) => state.users);
	const loading = useSelector<IStore, boolean>((state) => state.loading);
	const signedInUser = useSelector<IStore, User | null | undefined>((state) => state.user.info);
	const userGroups = useSelector<IStore, UserRole[]>((state) => state.users.groups);
	const [dialogOpen, setDialogOpen] = useState(false);
	const dispatch = useDispatch();
	const [selectedUser, setSelectedUser] = useState<User | null>(null);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [userToDelete, setUserToDelete] = useState<User>();

	useEffect(() => {
		dispatch(fetchUsers());
	}, [dispatch]);

	return (
		<Layout>
			<MaterialTable
				isLoading={loading}
				components={{
					Toolbar: (props) => (
						<Toolbar className={classes.tableToolbar} total={users.length} {...props}>
							<Button
								className={`${classes.pilabsRoundedBtn} ch-create-btn`}
								onClick={() => setDialogOpen(true)}
								startIcon={<AddIcon className={' ch-add-icon-create-btn'} />}
								size="small"
							>
								Create User
							</Button>
						</Toolbar>
					),
					Container: (props) => <Paper elevation={0} {...props} />,
					Header: (props) => <MTableHeader className={'ch-grey-text'} {...props} />,
					Row: (props) => <MTableBodyRow className={'ch-diplsay-none-if-empty'} {...props} />,
					Pagination: (props) => <Pagination {...props} />,
				}}
				options={{
					actionsColumnIndex: -1,
					search: false,
					filtering: true,
					maxBodyHeight: 'calc(100vh - 170px - 3.75rem)',
					pageSizeOptions: [5, 10, 15, 20, 25],
					headerStyle: {
						fontWeight: 600,
						fontSize: '.75rem',
						textTransform: 'uppercase',
						textDecoration: 'none',
						color: 'var(--trip-text-gray)',
					},
					emptyRowsWhenPaging: true,
				}}
				style={styles}
				title={'Users'}
				columns={columns}
				data={users}
				localization={{
					body: {
						emptyDataSourceMessage: 'No records to display',
						filterRow: {
							filterTooltip: 'filter',
						},
					},
				}}
				actions={[
					(u) => ({
						icon: 'edit',
						...getEditPermissionStatus(signedInUser, u as User, userGroups),
						onClick: (event, u) => {
							setSelectedUser(u as User);
							setDialogOpen(true);
						},
					}),
					(u) => ({
						icon: 'delete',
						...getDeletePermissionStatus(signedInUser, u as User, userGroups),
						onClick: (event, u) => {
							setUserToDelete(u as User);
							setDeleteDialogOpen(true);
						},
					}),
				]}
			/>
			<CreateUserDialog
				handleClose={() => {
					setDialogOpen(false);
					setSelectedUser(null);
				}}
				editedUser={selectedUser}
				open={dialogOpen}
			/>
			<DeleteUserDialog
				handleClose={() => setDeleteDialogOpen(false)}
				user={userToDelete}
				open={deleteDialogOpen}
			/>
		</Layout>
	);
};

export default UserManagement;

import { AxiosError } from 'axios';

const errorHandler = (error: AxiosError) => {
	if (error.response) {
		return new Error(error.response.data.message || error.response.data);
	}
	return error;
};

export default errorHandler;
